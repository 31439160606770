<template>
  <el-container>
    <el-main class="header">
      <el-header :class="{ sticky: isSticky, headerOpen: isActive }">
        <div class="logo" @click="toHome">
          <img src="../../assets/images/home/new-logo.svg" />
          <span>玄瑞科技</span>
          <!-- WUSI -->
        </div>
        <ul class="nav_menu" :class="{ open: isActive }" @click="to">
          <li :class="{ 'slide-in': isActive }" data-name="home">首页</li>
          <li :class="{ 'slide-in': isActive }" data-name="aboutUs">关于我们</li>
          <li v-for="item in categoryAList" :key="item.category_id" :class="{ 'slide-in': isActive }" data-name="articles"
            @click="getArticlesList(item.category_id, item.category_name, item.category_introduction)">
            {{ item.category_name }}
          </li>
          <!-- <li data-name="myuserinfo" v-show="isUserLogin">个人中心</li> -->
          <!-- <li
            v-show="!isUserLogin"
            :class="{ 'slide-in': isActive }"
            data-name="login"
          >
            登录
          </li> -->
        </ul>
        <div class="burger" :class="{ active: isActive }" @click="isActive = !isActive">
          <div class="top-line"></div>
          <div class="middle-line"></div>
          <div class="bottom-line"></div>
        </div>
      </el-header>
    </el-main>

    <!-- <el-main class="banner-box">
      <img src="../../assets/images/home/work.svg">
    </el-main> -->

    <el-main class="article-box">
      <div class="content-box">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-if="articleAList">
            {{ articleAList[0].category_name }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="title">
          {{ articleAList[0].title }}
        </div>
        <div class="pub-date">
          {{ formattedAArticleList[0].pub_date }}
        </div>
        <!-- <div class="author">
          {{ articleAList[0].author_name }}
        </div> -->
        <div class="content" v-html="articleAList[0].content"></div>
      </div>
    </el-main>

    <div class="to-top" :class="{ show: isSticky }" @click="toTop">TOP</div>

    <el-footer>
      <div class="footer-bottom">
        <div class="footer-logo">
          <img src="../../assets/images/home/bottom-logo.svg" alt="logo" />
          <span>玄瑞科技</span>
        </div>
        <ul class="footer-content">
          <li>版权所有© 苏州玄瑞信息科技有限公司•版本: v2.22.5.29</li>
          <!-- <li>地址：苏州高新区青山路1号1幢303室</li> -->
          <li>苏公网安备32050602011432号•苏ICP备16047967号-1</li>
          <!-- <li>联系电话：</li> -->
        </ul>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import "swiper/css/swiper.min.css";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      isSticky: false,
      isActive: false,
      message: "",
    };
  },
  computed: {
    ...mapState("login", ["isUserLogin"]),
    ...mapState("message", ["messageList", "status"]),
    ...mapState("home", ["categoryAList"]),
    ...mapState("articles", ["categoryId", "articleAList"]),
    ...mapGetters("articles", ["pages", "formattedAArticleList"]),
  },
  methods: {
    ...mapActions("message", {
      getMessageA: "getMessageA",
      addMessage: "addMessage",
    }),
    ...mapActions("articles", { getArticleA: "getArticleA" }),
    ...mapMutations("articles", {
      CHANGECATEGORYID: "CHANGECATEGORYID",
      CHANGECATEGORYNAME: "CHANGECATEGORYNAME",
      CHANGECURRENTPAGE: "CHANGECURRENTPAGE",
      CHANGECATEGORYINTRODUCTION: "CHANGECATEGORYINTRODUCTION"
    }),
    pubMessage() {
      if (this.isUserLogin == false) {
        this.$message({
          type: "warning",
          message: "请先登录!",
        });
      } else {
        if (this.message) {
          this.addMessage({
            messageContent: this.message,
            articleId: this.$route.query.articleId,
          }).then(() => {
            if (this.status == 200) {
              this.message = "";
              this.$message({
                type: "success",
                message: "已发送留言申请,请等待审核!",
              });
              this.getMessageA({
                currentPage: 1,
                pageSize: 30,
                articleId: this.$route.query.articleId,
              });
            }
          });
        }
      }
    },
    handleScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      setTimeout(() => {
        if (scrollTop > 0) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      }, 0);
    },
    getArticlesList(categoryId, categoryName, categoryIntroduction) {
      // 获取文章列表
      this.isActive = false;
      // console.log(categoryId, categoryName);
      this.CHANGECATEGORYID(categoryId);
      this.CHANGECATEGORYNAME(categoryName);
      this.CHANGECATEGORYINTRODUCTION(categoryIntroduction);
      // console.log(categoryId);
      // console.log(this.$route.query.categoryId);
      this.getArticleA({
        currentPage: 1,
        pageSize: 10,
        articleId: 0,
        // categoryId: this.$route.query.categoryId,
        categoryId: this.categoryId,
      });
    },
    toHome() {
      this.$router.push({ path: "/home" });
      this.toTop();
    },
    // toArticle(articleId) {
    //   this.$router.push("/article?articleId=" + articleId);
    // },
    back() {
      if (this.currentPage !== 1) {
        let nowPage = this.currentPage - 1;
        this.CHANGECURRENTPAGE(nowPage);
        this.getArticleA({
          currentPage: this.currentPage,
          pageSize: 10,
          articleId: 0,
          categoryId: this.categoryId,
        });
      }
    },
    next() {
      if (this.currentPage !== this.pages) {
        let nowPage = this.currentPage + 1;
        this.CHANGECURRENTPAGE(nowPage);
        this.getArticleA({
          currentPage: this.currentPage,
          pageSize: 10,
          articleId: 0,
          categoryId: this.categoryId,
        });
      }
    },
    to(e) {
      // this.CHANGECURRENTPAGE(1);
      this.isActive = false;
      let name = e.target.dataset.name;
      let elname = e.target.tagName;
      if (
        elname === "LI" &&
        (name === "home" || name === "aboutUs" || name === "myuserinfo" || name == "login")
      ) {
        this.$router.push("/" + name);
      } else if (elname === "LI") {
        this.$router.replace("/" + name + "?categoryId=" + this.categoryId);
      }
      // setTimeout(() => {
      //   if (name === "category") {
      //     this.CHANGEISSHOW(false);
      //   }
      // }, 1);
      this.toTop();
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getArticleA({
      currentPage: 1,
      pageSize: 10,
      articleId: this.$route.query.articleId,
      categoryId: 0,
    });
    this.getMessageA({
      currentPage: 1,
      pageSize: 30,
      articleId: this.$route.query.articleId,
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
body {
  color: #333;
  margin: 0;
  padding: 0;
  font-size: 16px;
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.el-container {
  padding: 0;
  margin: 0;
  background: rgb(231, 231, 231);

  .header {
    // position: -webkit-sticky;
    // position: sticky;
    z-index: 999;
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(179, 190, 188);
    overflow: hidden;

    .el-header {
      // padding: 0;
      // margin: 0;
      // background: rgb(125, 178, 190);
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 5vw;
      color: #fff;
      background-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
      transition: 0.6s;

      .logo {
        cursor: pointer;
        position: relative;
        max-height: 5vh;
        line-height: 5vh;
        // max-width: 5vh;
        font-weight: 500;
        color: #fff;
        font-size: 2em;
        text-transform: uppercase; // 字母小写转大写
        letter-spacing: 2px;
        transition: 0.6s;
        display: flex;
        flex: 1;

        img {
          // max-width: 5vh;
          max-height: 5vh;
        }
      }

      .nav_menu {
        position: relative;
        display: flex;
        justify-content: right;
        align-items: center;
        flex: 1;
        max-width: 1000px;

        li {
          cursor: pointer;
          position: relative;
          width: 100%;
          width: 90px;
          text-align: center;
          letter-spacing: 2px;
          font-weight: 500;
          transition: 0.3s;

          &:hover {
            opacity: 0.8;
            // color: rgb(1, 113, 138);
            color: rgb(73, 205, 252);
            // color: #333;
          }
        }
      }

      .burger {
        cursor: pointer;
        display: none;

        div {
          width: 25px;
          height: 4px;
          background: #fff;
          margin: 4px;
          transition: 0.3s ease-in-out 0.3s;
        }
      }

      .active {
        transform: translateX(0);

        div {
          transition: 0.3s ease-in-out 0.3s;
        }

        .top-line {
          transform: rotate(45deg) translate(5px, 6px);
        }

        .bottom-line {
          transform: rotate(-45deg) translate(5px, -6px);
        }

        .middle-line {
          opacity: 0;
          transform: translateX(50px);
          transition: 0.3s ease-in-out;
        }
      }

      @media screen and (max-width: 768px) {
        .logo {
          img {
            max-width: 16vh;
          }
        }

        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transform: translateX(100%);
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            line-height: 50px;
            width: 100%;
            transform: translateX(20px);
            // color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .burger {
          display: block;
        }

        .open {
          transform: translateX(0);
        }
      }
    }

    .headerOpen {
      background: #333;
      opacity: 0.8;
    }

    .swiper-box {
      transition: 0.6s;
      height: 0;
      // height: 100vh;
      overflow: hidden;
      width: 100vw;

      .swiper-container {
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
        --swiper-navigation-color: rgba(159,
            170,
            174,
            0.8);

        /* 单独设置按钮颜色 */
        // --swiper-navigation-size: 30px; /* 设置按钮大小 */
        .swiper-wrapper {
          cursor: pointer;
        }

        .swiper-button-prev,
        .swiper-button-next {
          transition: 0.3s;

          &:hover {
            --swiper-navigation-color: rgb(255, 255, 255);
          }
        }

        .swiper-pagination-bullet {
          width: 20px;
          height: 20px;
          background: #333;
          opacity: 0.8;
        }

        /deep/ .my-bullet-active {
          background: #ffffff;
          opacity: 1;
        }

        img {
          width: 100vw;
          height: 100vh;
          margin: 0 auto;
        }

        .swiper-title {
          position: absolute;
          top: 90vh;
          color: #fff;
          font-size: 24px;
          height: 10vh;
          width: 100vw;
          text-align: left;
          display: flex;
          justify-content: space-around;
          text-align: center;
          // align-items: center;
          // background: var(--swiper-navigation-color);
          background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
          // float: left;
          // z-index: 10;
        }
      }
    }

    .showbox {
      height: 100vh;
    }

    .sticky {
      height: 50px;
      padding: 5px 2vw;
      // background: #fff;
      // color: rgb(52, 52, 52);
      background: #fff;
      color: #222222;

      .logo {
        color: #222222;

        img {
          max-width: 5vh;
        }
      }

      .burger {
        div {
          background: #333;
        }
      }

      @media screen and (max-width: 768px) {
        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            width: 100%;
            line-height: 50px;
            color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .open {
          width: 40vw;
          top: 60px;
          background: #fff;
          opacity: 1;
        }
      }
    }
  }

  // .banner-box {
  //   height: 15vh;
  //   overflow: hidden;
  //   text-align: center;
  //   img {
  //     height: 15vh;
  //     max-width: 100%;
  //   }
  // }

  .article-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    padding: 10vh;
    background: #f1f0f0;

    .content-box {
      width: 100%;
      max-width: 1200px;
      margin-bottom: 20px;

      .el-breadcrumb {
        width: 20vw;
        border-top: 3px solid rgb(1, 113, 138);
        padding: 20px 20px 20px 0;
      }

      .title {
        font-size: 22px;
      }

      .pub-date {
        font-size: 14px;
        color: rgb(124, 124, 124);
      }

      .author {
        font-size: 14px;
        color: rgb(124, 124, 124);
      }
    }

    // .message-box {
    //   width: 100%;
    //   max-width: 1200px;
    //   margin-bottom: 20px;
    //   .message-content {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 14px;
    //     background: rgb(255, 255, 255);
    //     border: 1px solid rgb(138, 1, 1);
    //     margin: 10px 0;
    //     .avater {
    //       height: 50px;
    //       width: 50px;
    //       border-radius: 50px;
    //     }
    //     .message-right {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: space-between;
    //       height: 50px;
    //       width: 100%;
    //       margin-bottom: 10px;

    //       .top {
    //         width: 100%;
    //         display: flex;
    //         justify-content: space-between;
    //         .message-username {
    //           padding: 0 10px;
    //           color: rgb(138, 1, 1);
    //         }
    //         .pub-date {
    //           font-size: 14px;
    //           color: rgb(124, 124, 124);
    //         }
    //       }
    //       .bottom {
    //         padding: 0 10px;
    //         display: flex;
    //       }
    //     }
    //   }
    //   .pub-message {
    //     width: 100%;
    //     margin: 40px 0;
    //     border-radius: 5px;
    //     .pub-box {
    //       // display: flex;
    //       align-items: center;
    //       border: 1px solid rgb(138, 1, 1);
    //       padding: 10px 0 20px 0;
    //       background: #fff;
    //       .el-textarea {
    //         padding: 10px 0;
    //         border: none;
    //       }
    //       /deep/.el-textarea__inner {
    //         width: 90%;
    //         // border: 1px solid rgb(138, 1, 1);
    //         border: none;
    //         background: #f7f8f9;
    //         margin: 10px auto;
    //       }
    //     }
    //     .pub-title {
    //       padding: 10px;
    //       color: rgb(138, 1, 1);
    //     }
    //     .pub-right {
    //       display: flex;
    //       flex-direction: row-reverse;
    //       width: 95%;
    //       /deep/.el-button--danger.is-plain {
    //         // color: rgba(145, 66, 66, 0.8);
    //         color: #fff;
    //         // background: #fff8f8;
    //         background: rgba(145, 66, 66, 0.7);
    //         border-color: rgba(145, 66, 66, 0.7);
    //         transition: 0.3s;
    //         &:hover {
    //           color: rgba(145, 66, 66, 0.8);
    //           background: #fff8f8;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .to-top {
    position: fixed;
    opacity: 0;
    width: 5vh;
    height: 5vh;
    line-height: 5vh;
    margin: 2vw;
    border-radius: 5%;
    box-shadow: 0 2px 5px rgb(1, 113, 138);
    border: 1px solid rgb(1, 113, 138);
    text-align: center;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: 0.6s;
    transform: translateY(10vh);
    // background: #fff;
    background: rgba(1, 113, 138, 0.6);
    color: #fff;
    cursor: pointer;

    &:hover {
      // background: #fff;
      // color: rgb(0, 0, 0);
      // color: #333;
      background: rgb(1, 113, 138);
      box-shadow: 0 0 2vh rgb(1, 113, 138);
    }
  }

  .show {
    opacity: 1;
    transform: translateY(0vh);
    display: block;
  }

  .el-footer {
    padding: 0;
    margin: 0;
    background: #222222;
    height: 20vh !important;
    text-align: center;

    .footer-bottom {
      background: #222222;
      color: #8d8d8d;

      .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;

        img {
          height: 5vh;
          cursor: pointer;
        }
      }

      .footer-content {
        display: flex;
        // background: #302a29;
        // background: rgb(1, 113, 138);
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 5vh;
        overflow: hidden;

        li {
          margin: 10px;
          height: 5vh;
          line-height: 5vh;
          color: #8d8d8d;
          // color: #333;
          transition: 0.1s;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            // color: #333;
            // color: #fff;
            // background: rgb(230, 230, 230);
          }
        }
      }
    }
  }

  // 定义一个动画: 导航li标签 滑入动画 (至少设置一个形参name)
  .slide-in (@name) {
    @keyframes @name {
      0% {
        transform: translateX(20px);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  // 调用动画 (传入实参name)
  .slide-in(slideIn);

  // 定义形参
  .animation(@animation-name, @animation-duration, @animation-timing-function, @animation-delay, @play-state, @animation-iteration-count) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    -ms-animation: @arguments;
    -o-animation: @arguments;
    animation: @arguments;
  }

  // 执行
  .slide-in {
    .animation(slideIn, 0.3s, linear, 0.5s, forwards, 1);
  }
}
</style>

